#checkdv_1 {
  display: none;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(242.88deg, #273c64 2.98%, #815c72 99%);
}

.form-signin {
  max-width: 480px;
  padding: 40px;
  width: 480px;
  background: #fff;
  border-radius: 10px;
  position: relative;
}

.form-signin .icon-top {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 3px 30px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  display: flex;
  align-items: center;
  right: 41%;
  top: -11%;
  justify-content: center;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.login-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  margin: 2rem 0;
  text-align: center;
}

.duck-input {
  margin-bottom: 30px;
}
.duck-input .error {
  color: red;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 500;
  float: left;
  margin-top: 6px;
  margin-left: 4px;
  /* margin-bottom: 4px; */
}
.duck-input label {
  color: #484747;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
}

.duck-input input {
  border: 1px solid #e4e8e8;
  border-radius: 15px;
  height: 50px;
  padding: 20px 50px 20px 20px;
}
.pwd-icon input {
  background: url("../../assets/images/lock1.png") no-repeat;
  background-position: 97%;
}
.email-icon input {
  background: url("../../assets/images/email.png") no-repeat;
  background-position: 97%;
}
.forgot-keep {
  display: flex;
  justify-content: space-between;
}

.forgot-keep {
  display: flex;
  justify-content: space-between;
}

.forgot-keep .keep-login,
.forgot-keep .forgot-pwd {
  font-weight: 600;
  font-size: 12px;
  color: #828282;
}

.duck-btn {
  background: linear-gradient(267.78deg, #273c64 2.1%, #93356e 99.63%);
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  padding: 12px 50px;
}
.duck-btn:hover {
  color: #fff;
  box-shadow: 0px 3px 30px 10px rgba(0, 0, 0, 0.1);
}
.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.forgot-keep .forgot-pwd {
  font-style: italic;
  text-decoration: none;
}

.powered-by {
  position: absolute;
  display: flex;
  bottom: 20px;
  color: #fff;
  font-size: 14px;
  align-items: center;
}

.powered-by img {
  width: 130px;
  margin-left: 12px;
}

/*responsive*/
@media (max-width: 992px) {
}

@media (max-width: 767px) {
  .form-signin {
    width: 350px;
  }
  .form-signin .icon-top {
    right: 36%;
  }
}
