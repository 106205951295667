.red-circle {
  text-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
}
.green-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
}
.product {
  margin-top: 20px;
}
.me5 {
  margin-right: 16rem;
}
.col1 {
  width: 15%;
}
.col2 {
  width: 15%;
}
.col3 {
  width: 10%;
}
.col4 {
  width: 50%;
}
.col5 {
  width: 5%;
}
.table-css {
  background-color: rgb(206, 217, 208);
  color: black;
  /* border-bottom: 1px solid rgb(93, 151, 104); */
  border-radius: 5px;
}
.table-hover {
  background-color: #ebebeb;
}

@media (min-width: 2999px) {
  .me5 {
    margin-right: 86rem;
  }
  .head {
    margin-right: 70% !important;
  }
}
