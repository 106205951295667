body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto";
}
.red-circle {
  text-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
}
.green-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
}
