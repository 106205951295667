.box-leave {
  background-color: rgba(216, 216, 216, 0.707);
  height: 84px;
  /* height: 15.25%; */
  /* width: 289px; */
  width: auto;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  margin: 0 0 20px;
  padding: 15px;
}
.dropdown-toggle::after {
  content: none;
}
.badge1 {
  width: 80%;
}
