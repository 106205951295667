#checkdv_1 {
  display: none;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.nav-menu-duck {
  background: linear-gradient(180deg, #273c64 0%, #93356e 100%);
  height: 100vh;
  transition: all 0.2s ease-in-out;
  width: 4.5rem;
  display: flex;
  flex-direction: column;
  position: fixed;
}
.nav-menu-duck {
  /* background: linear-gradient(180deg, black 0%, black 100%); */
  background: linear-gradient(180deg, #20335a 0%, black 100%);
  /* background-color: black; */
  height: 100vh;
  transition: all 0.2s ease-in-out;
  width: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
}

.nav-menu-duck1 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #000000;
  z-index: 999;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  border-left: 3px solid #fff;
}

.dev-profile .dropdown-toggle {
  display: flex;
  flex-direction: column;
}

.dev-profile .dropdown-toggle::after {
  border: 0;
}

.dev-profile .dropdown-toggle .tripal-dot-menu {
  margin: 12px 0;
}

.search-bar .input-group input {
  border: 2px solid #5e6065;
  border-radius: 15px;
  border-left: 0px;
}

.flex-lg-1 {
  flex: 1 1 0% !important;
}

.top-menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 22px 32px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: #fafafa; */
  padding-left: 100px !important;
}

.page-title h4 {
  margin: 0;
  color: #484747;
  font-size: 24px;
  text-align: left !important;
}

.search-bar .input-group-text {
  border: 2px solid #5e6065;
  border-radius: 15px;
  background-color: #fff;
  border-right: 0px;
}

.notification-div a {
  position: relative;
}

.notification-div a .new-noti {
  background: #f1374a;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 20px;
}

.main-data {
  padding: 22px 32px;
}

.time-start-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-stemp-wrapper {
  display: flex;
}

.time-stemp-wrapper .date-stemp {
  background: url("../assets/images/calendar.png") no-repeat;
  background-position: 97%;
}

.time-stemp-wrapper .time-stemp {
  background: url("../assets/images/clock.png") no-repeat;
  background-position: 97%;
}

.time-stemp-wrapper input:first-child {
  margin-right: 22px;
}

.time-stemp-wrapper input {
  max-width: 250px;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.start-btn .btn {
  background: #94336e;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  color: #ffffff;
  font-size: 20px;
  border: 0;
  padding: 12px 24px;
}

.task-list {
  margin-top: 1rem;
}

.task-details-wrapper {
  display: flex;
  border-left: 0.5px solid rgba(0, 0, 0, 0.5);
  flex-direction: column;
}

.task-details {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  padding: 22px 0;
}

.task-details:last-child {
  border-bottom: 0px;
}

.task-details .Project-name {
  font-size: 18px;
  margin-bottom: 12px;
}

.task-details .ticket-number {
  font-size: 14px;
  background-color: #ebebeb;
  padding: 6px 12px;
  border-radius: 4px;
  margin-bottom: 12px;
}

.task-list .task {
  display: flex;
  align-items: center;
}

.task-list .task .task-time {
  width: 10%;
  text-align: center;
}

.task-list .task .task-details {
  padding: 12px;
  display: flex;
  width: 100%;
  align-items: center;
}

.Project-name {
  width: 25%;
  margin: 0 18px;
}

.ticket-number {
  width: 15%;
  text-align: center;
  margin: 0 18px;
}

.project-disc {
  width: 60%;
  margin: 0 18px;
}

.task-details-wrapper.edit-task .ticket-number {
  background: transparent;
}

.red-circle {
  text-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
}
.green-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
}
/*responsive*/
@media (max-width: 992px) {
  .task-list .task .task-details,
  .task-list .task {
    flex-direction: column;
  }

  .Project-name,
  .ticket-number,
  .project-disc,
  .task-list .task .task-time {
    width: 100%;
  }

  .task-details-wrapper {
    border-left: 0px;
  }

  .task-details-wrapper.edit-task .ticket-number {
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .m-hide {
    display: none;
  }

  .top-menu-wrapper {
    padding: 12px;
  }

  .nav-menu-duck {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
  }

  .tripal-dot-menu {
    display: none;
  }

  .search-bar {
    margin-left: 18px;
  }

  .main-data {
    padding: 12px;
  }

  .form-signin {
    width: 350px;
  }

  .form-signin .icon-top {
    right: 36%;
  }

  .time-stemp-wrapper {
    margin-top: 1rem;
  }

  .task-list .task .task-details,
  .task-list .task,
  .time-start-wrapper {
    flex-direction: column;
  }

  .Project-name,
  .ticket-number,
  .project-disc,
  .task-list .task .task-time {
    width: 100%;
  }

  .task-details-wrapper {
    border-left: 0px;
  }

  .task-details-wrapper.edit-task .ticket-number {
    padding: 0 !important;
  }
  .red-circle {
    text-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
  }
  .green-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: green;
  }
  .product {
    margin-top: 20px;
  }
  .me5 {
    margin-right: 16rem;
  }

  @media (min-width: 2999px) {
    .me5 {
      margin-right: 86rem;
    }
    .head {
      margin-right: 70% !important;
    }
  }
}

/* MAILBOX */
.mail {
  width: auto;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.167);
}
.button-34 {
  background: #5e5df0;
  border-radius: 999px;
  /* box-shadow: #5e5df0 0 10px 20px -10px; */
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-size: 20px;
  /* font-weight: 700; */
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 72px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}
.mail-txt {
  /* width: 70%; */
  border-radius: 5px;
  color: #0000007e;
}
.mail-txt:hover {
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.05;
  color: black;
  cursor: pointer;
}
.inbox {
  height: 17%;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.167);
  border-top: 1px solid rgba(0, 0, 0, 0.167);
}
.inbox:hover {
  background-color: #f1f5f9;
}
.inbox-msg {
  color: #00000090;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
}
.inbox-messages {
  margin-top: auto;
}
.inbox-messages-box {
  margin-top: 4%;
  /* height: 836px; */
  height: 80%;
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.167);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.box-2 {
  padding-left: 0.1%;
  padding-right: 0;
}
.input {
  /* background: transparent; */
  flex: 1;
  padding: 10px 20px;
  font-size: 20px;
  width: 60%;
  color: rgb(0, 0, 0);
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.548);
}
.input:focus {
  outline: none;
}
.icon {
  position: absolute;
  /* left: 0px; */
  /* top: 60px; */
}
@media (min-width: 2999px) {
  .big-screen {
    width: 61.7%;
    margin-bottom: 2%;
  }
}
